import { Image, Title, Text } from '@mantine/core';
import partner from '../images/partner.jpg';
import partnerLogo from '../images/partner-logo.svg';
import forest from '../images/forest.svg';
import mountain from '../images/mountain.svg';
import city from '../images/city.svg';
import scale from '../images/scale.svg';
import oldTech from '../images/old-tech.svg';
import approval from '../images/approval.svg';
import land from '../images/land.svg';
import invest from '../images/invest.svg';
import supply from '../images/supply.svg';
import employee from '../images/employee.svg';
import React from 'react';
import './partner.less';

const locations = [
  { key: 'forest', name: '森林', icon: forest },
  { key: 'mountain', name: '偏远山区', icon: mountain },
  { key: 'city', name: '城市', icon: city },
];

const preconditions = [
  { key: 'approval', title: '无需复杂环评、批建', description: '', icon: approval },
  { key: 'land', title: '无需大片土地', description: '每10万吨制浆产能，仅需200平方米场地', icon: land },
  { key: 'invest', title: '无需大量投资', description: '同样产能下，我们的设备投资额仅为传统蒽醌法双氧水工厂的三分之一', icon: invest },
  { key: 'supply', title: '无需连续原料供应', description: '无需传统双氧水生产持续的氢气供应、无需各种有机耗材供应、无需蒸汽……', icon: supply },
  { key: 'employee', title: '无需大量人员高成本运维', description: '远程DCS控制，定期巡检即可……', icon: employee },
];

const actions = [
  { key: 'contact', title: '联系我们', description: ['info@chengyangtech.com'] },
  { key: 'land', title: '找一小块场地', description: ['每10万吨制浆产能，仅需200平方米场地'] },
  { key: 'wait', title: '等待3个月时间', description: ['生产、运输、安装、调试'] },
];

function Partner() {
  return (
    <div className="partner">
      <section className="bg">
        <Image className="partner-bg" src={partner} />
        <img className="partner-logo" src={partnerLogo} />
        <div className='text-tag'>我们的理念就是成为制浆造纸企业的 「双氧水工厂伙伴」！</div>
      </section>
      <section className="intro">
        <Title className="title">橙氧科技双氧水现场生产设备从研发之初即面向制浆造纸行业，满足制浆造纸行业的需求——无论是单元模块的产能匹配、还是单元模块的环境适应性。我们的理念就是成为制浆造纸企业的 「双氧水工厂伙伴」！</Title>
        <Text className="description">无论您的工厂在哪里，森林里、偏远山区或是城市里；无论规模大小，10万吨或是200万吨制浆产能；也无论您是否已经有了双氧水供应商或是已经自建了配套的传统蒽醌法双氧水生产工厂。我们的技术与合作理念可以给您最高性价比的解决方案！</Text>
      </section>
      <section className="no-matter">
        <div className='location'>
          <Title className='title' order={1}><span><span className='prefix'>无论</span>工厂在哪里</span></Title>
          <div className="content locations">
            {locations.map(({key, name, icon}) => (
              <div key={key} className="item">
                <img src={icon}/>
                <span>{name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className='scale'>
          <Title className='title' order={1}><span><span className='prefix'>无论</span>工厂规模</span></Title>
          <div className="content">
            <img src={scale}/>
          </div>
        </div>
        <div className='old-tech'>
          <Title className='title' order={1}><span><span className='prefix'>无论</span>是否自建传统双氧水生产工厂</span></Title>
          <div className="content">
            <img src={oldTech}/>
          </div>
        </div>
      </section>
      <section className="no-precondition">
        <Title className='title' order={1}>橙氧科技双氧水伙伴<span>无需</span>以下苛刻条件</Title>
        <div className="list">{preconditions.map(({key, icon, title, description}, index) => (
          <div key={key} className="item">
            <div className="index">{index + 1}</div>
            <img src={icon}/>
            <div className="text">
              <Title className="title" order={2}>{title}</Title>
              {description && (<Text className="description">{description}</Text>)}
            </div>
          </div>
        ))}</div>
      </section>
      <section className="actions">
        <Title className='title' order={1}>只需要三步</Title>
        <Title className='sub-title' order={2}>双氧水供应将不再成为烦恼</Title>
        <div className="list">{actions.map(({key, title, description}, index) => (
          <div key={key} className="item">
            <div className="index">{index + 1}</div>
            <Title className="title" order={2}>{title}</Title>
            {description.map(item => (<Text className="description">{item}</Text>))}
          </div>
        ))}</div>
      </section>
    </div>
  );
}

export default Partner;
